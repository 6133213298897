import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useReduxView } from '../../hooks/';
import { Preloader, AgeVerificationModal } from '../../components';

import useQuery from '../../hooks/useQuery';
import { NotFound404, LandingFortuneChest } from '../../pages';
import NewLanding from '../../pages/NewLanding/NewLanding';
import OnlyForm from '../../pages/OnlyForm';

export const Enter = () => {
  const loaded = useReduxView();
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const initialCurrent = localStorage.getItem('current-lang');
  const words = useSelector((state) => state.words);
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    if (initialCurrent === 'undefined') localStorage.removeItem('current-lang');
  }, [initialCurrent]);

  useEffect(() => {
    const key = query.get('key');
    if (key) {
      localStorage.setItem('cmd-key', key);
    }

    const isFirstVisit = localStorage.getItem('user-first-visit');

    if (!isFirstVisit && isFirstVisit !== '1') {
      localStorage.setItem('user-first-visit', '1');
    } else {
      localStorage.setItem('user-first-visit', '0');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    const storedVerification = localStorage.getItem('isAgeVerified');
    setIsAgeVerified(storedVerification === 'true' ? true : null);
  }, []);

  const [isAgeVerified, setIsAgeVerified] = useState(false);

  const handleAgeVerification = (isVerified) => {
    localStorage.setItem('isAgeVerified', isVerified.toString());
    setIsAgeVerified(isVerified);
  };

  const checkIfUserAuth = useCallback(() => {
    return (
      <>
        <Routes>
          <Route exact path="/" element={<OnlyForm />} />
          <Route exact path="/chest" element={<LandingFortuneChest />} />
          <Route exact path="/new-landing" element={<NewLanding />} />

          <Route path="/404" element={<NotFound404 />} />
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </>
    );
  }, []);
  if (!isAgeVerified && settings?.wheel?.ageVerification) {
    return words.server || words.local ? <AgeVerificationModal onAgeVerification={handleAgeVerification} /> : <Preloader />;
  }

  return <>{!loaded ? <Preloader /> : checkIfUserAuth()}</>;
};
