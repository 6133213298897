import React from 'react';
import classNames from 'classnames';

export const Line = ({ moving, prizeNumber, duration, onMoveEnd }) => {
  return (
    <div
      onAnimationEnd={onMoveEnd}
      className={classNames('chest_select-section__footer__chest_list__line', `chest_select-section__footer__chest_list__line__duration-${duration}`, {
        [`chest_select-section__footer__chest_list__line__move-to-${prizeNumber}`]: moving,
        [`chest_select-section__footer__chest_list__line__finished-${prizeNumber}`]: prizeNumber > 0,
      })}
    />
  );
};
