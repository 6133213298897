import styled from 'styled-components';

export const YellowTitle = styled.h2`
  color: #faff00;
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  font-size: 45px;
  text-align: center;

  @media (max-width: 776px) {
    font-size: 35px;
    text-align: left;
  }
`;
