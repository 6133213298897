import { translateField } from '../../shared/utils';

export const createGamesData = (words) => {
  return [
    {
      img: '/images/landing/game1.webp',
      head: translateField('head', 'games.game1', words),
      star: translateField('star', 'games.game1', words),
      text: translateField('text', 'games.game1', words),
    },
    {
      img: '/images/landing/game2.webp',
      head: translateField('head', 'games.game2', words),
      star: translateField('star', 'games.game2', words),
      text: translateField('text', 'games.game2', words),
    },
    {
      img: '/images/landing/game3.webp',
      head: translateField('head', 'games.game3', words),
      star: translateField('star', 'games.game3', words),
      text: translateField('text', 'games.game3', words),
    },
    {
      img: '/images/landing/game4.webp',
      head: translateField('head', 'games.game4', words),
      star: translateField('star', 'games.game4', words),
      text: translateField('text', 'games.game4', words),
    },
    {
      img: '/images/landing/game5.webp',
      head: translateField('head', 'games.game5', words),
      star: translateField('star', 'games.game5', words),
      text: translateField('text', 'games.game5', words),
    },
    {
      img: '/images/landing/game6.webp',
      head: translateField('head', 'games.game6', words),
      star: translateField('star', 'games.game6', words),
      text: translateField('text', 'games.game6', words),
    },
    {
      img: '/images/landing/game7.webp',
      head: translateField('head', 'games.game7', words),
      star: translateField('star', 'games.game7', words),
      text: translateField('text', 'games.game7', words),
    },
    {
      img: '/images/landing/game8.webp',
      head: translateField('head', 'games.game8', words),
      star: translateField('star', 'games.game8', words),
      text: translateField('text', 'games.game8', words),
    },
    {
      img: '/images/landing/game9.webp',
      head: translateField('head', 'games.game9', words),
      star: translateField('star', 'games.game9', words),
      text: translateField('text', 'games.game9', words),
    },
    {
      img: '/images/landing/game10.webp',
      head: translateField('head', 'games.game10', words),
      star: translateField('star', 'games.game10', words),
      text: translateField('text', 'games.game10', words),
    },
  ];
};

export const createBonusesData = (words) => {
  return [
    {
      img: '/images/landing/bonus1.webp',
      head: translateField('head', 'bonuses.bonus1', words),
      text: translateField('text', 'bonuses.bonus1', words),
    },
    {
      img: '/images/landing/bonus2.webp',
      head: translateField('head', 'bonuses.bonus2', words),
      text: translateField('text', 'bonuses.bonus2', words),
    },
    {
      img: '/images/landing/bonus3.webp',
      head: translateField('head', 'bonuses.bonus3', words),
      text: translateField('text', 'bonuses.bonus3', words),
    },
    {
      img: '/images/landing/bonus4.webp',
      head: translateField('head', 'bonuses.bonus4', words),
      text: translateField('text', 'bonuses.bonus4', words),
    },
  ];
};
