import styled from 'styled-components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import TopBlock from './TopBlock';
import Games from './Games';
import Bonuses from './Bonuses';
import Payment from './Payment';
import Header from './Header';
import Footer from './Footer';
import FullForm from './FullForm';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  justify-content: center;
  align-items: center;

  @media (max-width: 776px) {
    margin-top: 0px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1140px;

  @media (max-width: 776px) {
    width: 100%;
    padding: 0 2.5%;
  }

  @media (max-width: 1139px) {
    width: 100%;
    padding: 0 15px;
  }
`;

const NewLanding = () => {
  const [showRegBlock, setShowRegBlock] = useState(false);

  useEffect(() => {
    const isOpen = window?.settings?.is_form_open;

    if (isOpen) {
      setShowRegBlock(true);
    }
  }, []);

  useEffect(() => {
    if (showRegBlock) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [showRegBlock]);

  return (
    <Container
      className={classNames('roulette-section', {
        'roulette-section--show-form': showRegBlock,
      })}
    >
      <Wrapper>
        <Header />
        <TopBlock setShowRegBlock={setShowRegBlock} />
        <Games setShowRegBlock={setShowRegBlock} />
        <Bonuses setShowRegBlock={setShowRegBlock} />
        <Payment />
      </Wrapper>
      {showRegBlock && <FullForm setShowRegBlock={setShowRegBlock} />}
      <Footer />
    </Container>
  );
};

export default NewLanding;
