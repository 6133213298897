import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import ReactInlineSvg from 'react-inlinesvg';
import { useSelector } from 'react-redux';
import { ButtonTypes, ButtonLanguages } from '../../elements';
import { Auth } from '../Auth/Auth';
import { translateField } from '../../shared/utils';
import CustomWheel from './wheel/Wheel';
import Chest from './chest/Chest';

const ALL_ATTEMPTS = 2;
const USED_ATTEMPTS = localStorage.getItem('prizeIndexes') ? JSON.parse(localStorage.getItem('prizeIndexes')).length : 0;
const HAS_ATTEMPTS = ALL_ATTEMPTS - USED_ATTEMPTS;

export const Roulette = () => {
  const [attemptCount, setAttemptCount] = useState(HAS_ATTEMPTS);
  const [prizeIndex, setPrizeIndex] = useState(localStorage.getItem('prizeIndexes') ? JSON.parse(localStorage.getItem('prizeIndexes')).length : 0);
  const [prize, setPrize] = useState(null);
  const [showRegBlock, setShowRegBlock] = useState(HAS_ATTEMPTS === 0);
  const words = useSelector((state) => state.words);
  const settings = useSelector((state) => state.settings);
  const size = useSelector((state) => state.handling.size);
  const localPrize = localStorage.getItem('prize');
  const refInfo = useRef(null);

  const wheelData = [
    { option: translateField('fortune_option_1', 'wheel.fortune', words, false), key: 'fortune_option_1', style: { backgroundColor: '#7a0000' } },
    { option: translateField('fortune_option_2', 'wheel.fortune', words, false), key: 'fortune_option_2', style: { backgroundColor: '#151520' } },
    { option: translateField('fortune_option_3', 'wheel.fortune', words, false), key: 'fortune_option_3', style: { backgroundColor: '#7a0000' } },
    { option: translateField('fortune_option_4', 'wheel.fortune', words, false), key: 'fortune_option_4', style: { backgroundColor: '#151520' } },
    { option: translateField('fortune_option_5', 'wheel.fortune', words, false), key: 'fortune_option_5', style: { backgroundColor: '#7a0000' } },
    { option: translateField('fortune_option_6', 'wheel.fortune', words, false), key: 'fortune_option_6', style: { backgroundColor: '#151520' } },
    { option: translateField('fortune_option_7', 'wheel.fortune', words, false), key: 'fortune_option_7', style: { backgroundColor: '#7a0000' } },
    { option: translateField('fortune_option_8', 'wheel.fortune', words, false), key: 'fortune_option_8', style: { backgroundColor: '#151520' } },
  ];

  const addPrizeIndexToLocalStorage = (index) => {
    const prizeIndexesArray = localStorage.getItem('prizeIndexes') ? JSON.parse(localStorage.getItem('prizeIndexes')) : [];
    localStorage.setItem('prizeIndexes', JSON.stringify([...prizeIndexesArray, index]));
  };

  const prizeIndexes = localStorage.getItem('prizeIndexes');
  const bonuses = prizeIndexes ? JSON.parse(prizeIndexes).map((number) => wheelData[number]) : [];
  const [sizeWidthRoullette, setSizeWidthRoullette] = useState(window.innerWidth);
  const [sizeHeightRoullette, setSizeHeightRoullette] = useState(window.innerHeight);
  // Set initial dimensions immediately
  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      const minDimension = Math.min(newWidth, newHeight);

      setSizeWidthRoullette(minDimension);
      setSizeHeightRoullette(minDimension);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Initialize sizes on component mount
    handleResize();

    // Detach the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this runs only on mount
  console.log(bonuses);
  useEffect(() => {
    if (!settings?.wheel?.fortune) {
      localStorage.setItem('prizeIndexes', JSON.stringify([1, 2]));
      setAttemptCount(0);
      setShowRegBlock(true);
    }
    if (!localStorage.getItem('prizeIndexes')) localStorage.removeItem('prizeIndexes');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={classNames('roulette-section', {
        'roulette-section--show-form': showRegBlock,
      })}
      style={{
        background: ` url('/uploads/${size?.device}_${size?.orientation}.webp') no-repeat center / cover`,
      }}
    >
      {showRegBlock && (
        <div className="show-form">
          <img className="logo" src="/logo.png" alt="logo" />
          <div className="show-form_content">
            <Auth />
          </div>
          <ButtonTypes className="roulette-section__link" location="roulette-section__link-site" onClick={() => window.open(settings.redirect, '_self')}>
            <span>{translateField('have_account', 'roulette', words, false)}</span>
            <span>{translateField('to_site', 'roulette', words, false)}</span>
            <ReactInlineSvg className="icon-arrow" src="/images/icons/arrow-right.svg" alt="to_login" width="10" height="10" />
          </ButtonTypes>
          {!size.mobile && <ButtonLanguages />}
        </div>
      )}

      {!showRegBlock && (
        <>
          <div className="roulette-section__content">
            <img src="/images/wheel/chest_with_chips.png" alt="chest_with_chips" className="roulette-section__content__chest-with-chips" />

            <div
              className="roulette-section__content__spin"
              style={{
                width: size.width < 768 ? null : sizeWidthRoullette,
                height: size.width < 768 ? null : sizeHeightRoullette,

                maxWidth:
                  size.width < 768
                    ? null
                    : size.height > 992 && size.width > 993 && size.width < 1440
                      ? `calc(${sizeWidthRoullette}px - (var(--vh) * 25))`
                      : `calc(${sizeWidthRoullette}px - (var(--vh) * 15))`,
                maxHeight:
                  size.width < 768
                    ? null
                    : size.height > 992 && size.width > 993 && size.width < 1440
                      ? `calc(${sizeWidthRoullette}px - (var(--vh) * 25))`
                      : `calc(${sizeWidthRoullette}px - (var(--vh) * 15))`,
              }}
            >
              <CustomWheel
                prizeIndex={prizeIndex}
                attemptCount={attemptCount}
                data={wheelData}
                onStartSpinning={() => {
                  setAttemptCount(attemptCount - 1);
                  const nextIndex = prizeIndex % wheelData.length;
                  setPrizeIndex(nextIndex + 1);
                }}
                onStopSpinning={() => {
                  addPrizeIndexToLocalStorage(prizeIndex);

                  setTimeout(() => {
                    if (attemptCount === 0) {
                      setShowRegBlock(true);
                    }
                  }, 2000);
                  setPrize(wheelData[prizeIndex]);
                  wheelData[prizeIndex].style = { backgroundColor: '#7720AC' };
                }}
              />
            </div>
          </div>

          <div className="roulette-section__info" ref={refInfo}>
            <ButtonTypes className="roulette-section__link" location="roulette-section__link-site" onClick={() => window.open(settings.redirect, '_self')}>
              <span>{translateField('have_account', 'roulette', words, false)}</span>
              <span>{translateField('to_site', 'roulette', words, false)}</span>
              <ReactInlineSvg className="icon-arrow" src="/images/icons/arrow-right.svg" alt="to_login" width="10" height="10" />
            </ButtonTypes>
            <Chest attemptCount={attemptCount} />

            <div
              className={classNames('roulette-section__win', {
                show: prize ?? localPrize,
              })}
            >
              <div className="title">{translateField('u_win_bonus', 'roulette', words, true)}</div>
              <div className="roulette-section__bonus" style={localPrize || prize ? { opacity: 1 } : null}>
                <span>{translateField(prize?.key ?? localPrize, 'wheel.fortune', words, true)}</span>
              </div>
            </div>

            {!size.mobile && <ButtonLanguages />}
          </div>
        </>
      )}

      {size.mobile && <ButtonLanguages />}
    </div>
  );
};
