import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { translateField } from '../../shared/utils';

const Container = styled.header`
  position: relative;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 130px;
`;

const Links = styled.div`
  display: flex;

  @media (max-width: 776px) {
    display: none;
  }
`;

const Link = styled(AnchorLink)`
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  &:active {
    color: red;
  }

  @media (max-width: 776px) {
    margin-bottom: 25px;
  }
`;

const Icon = styled.div`
  @media (max-width: 776px) {
    display: block;
  }

  display: none;
`;

const MobileLinks = styled.div`
  position: absolute;
  top: 66px;
  width: 100%;
  min-height: 40px;
  padding-top: 10px;
  background: #1e1717;

  display: flex;
  flex-direction: column;
`;

const OpenClose = ({ isOpen, setIsOpen }) => {
  return (
    <Icon onClick={() => setIsOpen((prev) => !prev)}>
      {isOpen ? (
        <svg height={25} width={25} aria-hidden="true" tabindex="0" class="e-font-icon-svg e-far-window-close" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
          <path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6L295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z"></path>
        </svg>
      ) : (
        <svg height={25} width={25} aria-hidden="true" tabindex="0" class="e-font-icon-svg e-fas-align-justify" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
          <path d="M432 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-128H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path>
        </svg>
      )}
    </Icon>
  );
};

const Header = () => {
  const [active, setActive] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const words = useSelector((state) => state.words);

  const DATA = [
    ['#home', translateField('link1', 'header', words)],
    ['#games', translateField('link2', 'header', words)],
    ['#bonuses', translateField('link3', 'header', words)],
    ['#payments', translateField('link4', 'header', words)],
  ];

  return (
    <Container>
      <Logo src="/images/landing/logo-1.png" />
      <Links>
        {DATA.map(([id, name], i) => (
          <Link style={{ color: id === active ? '#18a189' : 'white' }} onClick={() => setActive(id)} href={id} key={i}>
            {name}
          </Link>
        ))}
      </Links>
      <OpenClose setIsOpen={setIsOpen} isOpen={isOpen} />
      {isOpen && (
        <MobileLinks>
          {DATA.map(([id, name], i) => (
            <Link
              style={{ color: id === active ? '#18a189' : 'white' }}
              onClick={() => {
                setActive(id);
                setIsOpen(false);
              }}
              href={id}
              key={i}
            >
              {name}
            </Link>
          ))}
        </MobileLinks>
      )}
    </Container>
  );
};

export default Header;
