import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { translateField } from '../../shared/utils';
import { YellowTitle } from './common';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 80%;

  @media (max-width: 776px) {
    width: 100%;
    flex-wrap: wrap;
    row-gap: 40px;
  }
`;

const RowEl = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 776px) {
    width: 50%;
  }
`;

const Img = styled.img`
  margin-right: 20px;
`;

const RowText = styled.div`
  color: #6ec1e4;
  font-family: 'Roboto', Sans-serif;
  font-size: 16px;
  line-height: 1.6em;
  font-weight: 600;
`;

const BtcContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 35px;

  @media (max-width: 776px) {
    row-gap: 40px;
  }
`;

const BtcEl = styled.div`
  display: flex;
  align-items: center;
  width: 33%;

  @media (max-width: 776px) {
    width: 50%;
  }
`;

const BtcImg = styled(Img)`
  width: 32px;
  height: 32px;
`;

const Payment = () => {
  const words = useSelector((state) => state.words);

  return (
    <Container id="payments">
      <YellowTitle>{translateField('head', 'payment', words, false)}</YellowTitle>
      <Row>
        <RowEl>
          <Img src="/images/landing/payment/PayTo.svg" />
          <RowText>{translateField('row0', 'payment', words, false)}</RowText>
        </RowEl>
        <RowEl>
          <Img src="/images/landing/payment/visa-3-svgrepo-com.svg" />
          <RowText>{translateField('row1', 'payment', words, false)}</RowText>
        </RowEl>
        <RowEl>
          <Img src="/images/landing/payment/mastercard.svg" />
          <RowText>{translateField('row2', 'payment', words, false)}</RowText>
        </RowEl>
        <RowEl>
          <Img src="/images/landing/payment/Pay-ID.svg" />
          <RowText>{translateField('row3', 'payment', words, false)}</RowText>
        </RowEl>
      </Row>

      <YellowTitle>Which cryptocurrencies can be used in pokies24?</YellowTitle>
      <BtcContainer>
        <BtcEl>
          <BtcImg src="/images/landing/payment/eth.svg" />
          <RowText>{translateField('row4', 'payment', words, false)}</RowText>
        </BtcEl>
        <BtcEl>
          <BtcImg src="/images/landing/payment/btc.svg" />
          <RowText>{translateField('row5', 'payment', words, false)}</RowText>
        </BtcEl>
        <BtcEl>
          <BtcImg src="/images/landing/payment/ltc.svg" />
          <RowText>{translateField('row6', 'payment', words, false)}</RowText>
        </BtcEl>

        <BtcEl>
          <BtcImg src="/images/landing/payment/trx.svg" />
          <RowText>{translateField('row7', 'payment', words, false)}</RowText>
        </BtcEl>
        <BtcEl>
          <BtcImg src="/images/landing/payment/doge.svg" />
          <RowText>{translateField('row8', 'payment', words, false)}</RowText>
        </BtcEl>
        <BtcEl>
          <BtcImg src="/images/landing/payment/xrp.svg" />
          <RowText>{translateField('row9', 'payment', words, false)}</RowText>
        </BtcEl>

        <BtcEl>
          <BtcImg src="/images/landing/payment/usdc_erc20.svg" />
          <RowText>{translateField('row10', 'payment', words, false)}</RowText>
        </BtcEl>
        <BtcEl>
          <BtcImg src="/images/landing/payment/usdt_erc20.svg" />
          <RowText>{translateField('row11', 'payment', words, false)}</RowText>
        </BtcEl>
        <BtcEl>
          <BtcImg src="/images/landing/payment/usdt_erc20.svg" />
          <RowText>{translateField('row12', 'payment', words, false)}</RowText>
        </BtcEl>
      </BtcContainer>
    </Container>
  );
};

export default Payment;
