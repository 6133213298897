import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { translateField } from '../../shared/utils';

const Container = styled.footer`
  width: 100%;
  min-height: 450px;
  background: #282828;
  box-sizing: border-box;

  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1140px;
  box-sizing: border-box;
  padding-top: 50px;

  @media (max-width: 776px) {
    width: 100%;
    padding: 0 15px;

    padding-top: 30px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  @media (min-width: 777px) and (max-width: 1139px) {
    width: 100%;
    padding: 0 15px;
    padding-top: 40px;
    flex-wrap: wrap;
    margin-right: 15%;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;

  &#first {
    @media (max-width: 776px) {
      width: 100%;
      margin-bottom: 50px;
    }
  }

  &#second {
    @media (max-width: 776px) {
      margin-right: 50px;
    }
  }

  &#last {
    @media (max-width: 1139px) {
      margin-top: 30px;
      margin-bottom: 50px;
      width: 100%;
    }
  }
`;

const Logo = styled.img`
  width: 130px;
`;

const Title = styled.h4`
  margin-top: 0;
  font-size: 28px;
  line-height: 1.2em;
  color: #f2fbff;
  font-weight: bold;

  @media (max-width: 776px) {
    font-size: 18px;
    margin-bottom: 15px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  color: #7a7a7a;
  line-height: 1.2em;
  font-weight: bold;
  margin-bottom: 30px;
  cursor: pointer;

  @media (max-width: 776px) {
    margin-bottom: 20px;
  }
`;

const BoldText = styled.div`
  color: #f2fbff;
  font-size: 16px;
  line-height: 1.4em;
  font-weight: bold;
  margin-bottom: 20px;
  max-width: 220px;
  white-space: pre-line;

  @media (max-width: 776px) {
    max-width: 90%;
    font-size: 14px;
    margin-bottom: 20px;
  }

  @media (max-width: 1139px) and (min-width: 777px) {
    max-width: 60%;
  }
`;

const Icons = styled.div`
  margin-top: auto;
  display: flex;
`;

const Rights = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);

  color: #7a7a7a;
  font-size: 16px;
  line-height: 1.2em;
  font-weight: bold;

  @media (max-width: 776px) {
    font-size: 14px;
    white-space: nowrap;
  }
`;

const Footer = () => {
  const words = useSelector((state) => state.words);

  return (
    <Container>
      <Wrapper>
        <Column id="first">
          <Logo src="/images/landing/logo-1.png" />
          <Icons></Icons>
        </Column>
        <Column id="second">
          <Title>{translateField('head', 'footer.col1', words)}</Title>
          <Description>{translateField('text1', 'footer.col1', words)}</Description>
          <Description>{translateField('text2', 'footer.col1', words)}</Description>
          <Description>{translateField('text3', 'footer.col1', words)}</Description>
        </Column>
        <Column>
          <Title>{translateField('head', 'footer.col2', words)}</Title>
          <Description>{translateField('text1', 'footer.col2', words)}</Description>
          <Description>{translateField('text2', 'footer.col2', words)}</Description>
          <Description>{translateField('text3', 'footer.col2', words)}</Description>
        </Column>
        <Column id="last">
          <BoldText>{translateField('support', 'footer', words)} </BoldText>
          <BoldText>{translateField('contact', 'footer', words)}</BoldText>
        </Column>
        <Rights>{translateField('rights', 'footer', words)}</Rights>
      </Wrapper>
    </Container>
  );
};

export default Footer;
