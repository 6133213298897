import React from 'react';
import { ChestSelect } from '../../../components';

export const LandingFortuneChest = () => {
  return (
    <>
      <ChestSelect />
    </>
  );
};
