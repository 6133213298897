import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { translateField } from '../../shared/utils';

const Container = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: 955px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

const TitleBlock = styled.div`
  width: 50%;
  margin-right: 35px;

  @media (max-width: 955px) {
    width: 100%;
    margin: 0;
  }
`;

const Title = styled.h1`
  color: #f2fbff;
  font-weight: 600;
  font-family: 'Roboto', Sans-serif;
  line-height: 1.4em;
  font-size: 60px;
  margin-top: 10px;
  margin-bottom: 15px;

  @media (max-width: 776px) {
    font-size: 42px;
    line-height: 1.4em;
    margin-top: 15px;
  }
`;

const Description = styled.div`
  color: #f2fbff;
  font-family: 'Roboto', Sans-serif;
  font-size: 16px;
  line-height: 1.6em;
  font-weight: 600;
  margin-bottom: 25px;
`;

const Button = styled.div`
  border-radius: 12px 12px 12px 12px;
  background-color: #61ce70;
  padding: 12px 14px;
  color: #fff;
  box-sizing: border-box;
  height: 39px;
  min-width: 179px;
  max-width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Roboto', Sans-serif;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
`;

const Background = styled.div`
  background-image: url('images/landing/payment/bg-bonus.webp');
  width: 50%;
  height: 379px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 955px) {
    width: 100%;
    margin-top: 15px;
  }
`;

const BackgroundText = styled.h3`
  color: #f2fbf2;
  font-family: 'Roboto', Sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 35px;
  text-align: center;
  line-height: 1.3em;
  margin-bottom: 15px;

  @media (max-width: 776px) {
    font-size: 30px;
  }
`;

const ImageRow = styled.div`
  background-image: url('images/landing/payment/payment.svg');
  background-repeat: no-repeat;
  background-position: top center;
  margin-top: 25px;
  width: 100%;
  height: 100%;
`;

const SecondImageRow = styled(ImageRow)`
  background-image: url('images/landing/payment/currency.svg');
  margin-top: 10px;

  @media (max-width: 776px) {
    font-size: 30px;
    margin-top: 0px;
    width: 80%;
    background-size: contain;
    background-position: top center;
  }
`;

const TopBlock = ({ setShowRegBlock }) => {
  const words = useSelector((state) => state.words);

  return (
    <Container id="home">
      <TitleBlock>
        <Title>{translateField('head', 'topblock', words)}</Title>
        <Description>{translateField('description', 'topblock', words)}</Description>
        <Button onClick={() => setShowRegBlock(true)}>{translateField('button1', 'topblock', words)}</Button>
      </TitleBlock>
      <Background>
        <BackgroundText class="elementor-heading-title elementor-size-default">
          {translateField('deposit1', 'topblock', words)}
          <br />
          {translateField('deposit2', 'topblock', words)}
          <br />
          {translateField('deposit3', 'topblock', words)}
        </BackgroundText>
        <Button onClick={() => setShowRegBlock(true)}>{translateField('button1', 'topblock', words)}</Button>
        <ImageRow />
        <SecondImageRow />
      </Background>
    </Container>
  );
};

export default TopBlock;
