import {
  GET_BALANCE,
  GET_BALANCE_ERROR,
  GET_PAYMENT,
  GET_PAYMENT_DEPOSIT,
  GET_PAYMENT_ERROR,
  GET_PAYMENT_ERROR_DEPOSIT,
  GET_PAYMENT_ERROR_WITHDRAW,
  GET_PAYMENT_WITHDRAW,
  SET_BALANCE,
  SET_PAYMENT,
  SET_PAYMENT_DEPOSIT,
  SET_PAYMENT_WITHDRAW,
} from '../actions/actionTypes';

const initialState = {
  onlinePayment: null,
  onlinePaymentDeposit: null,
  balanceHistory: null,
  paymentError: null,
  paymentLoading: false,
  paymentSuccess: null,
  balanceError: null,
  onlinePaymentDepositError: null,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT || GET_PAYMENT_DEPOSIT || GET_BALANCE:
      return {
        ...state,
      };
    case SET_PAYMENT:
      return {
        ...state,
        onlinePayment: action.payload,
      };
    case SET_PAYMENT_DEPOSIT:
      return {
        ...state,
        onlinePaymentDeposit: action.payload,
      };
    case GET_PAYMENT_WITHDRAW:
      return {
        ...state,
        paymentLoading: action.payload,
      };
    case SET_PAYMENT_WITHDRAW:
      return {
        ...state,
        paymentSuccess: action.payload,
      };
    case GET_PAYMENT_ERROR:
      return {
        ...state,
        infoError: action.payload,
      };
    case GET_BALANCE_ERROR:
      return {
        ...state,
        balanceError: action.payload,
      };
    case GET_PAYMENT_ERROR_DEPOSIT:
      return {
        ...state,
        onlinePaymentDepositError: action.payload,
      };
    case GET_PAYMENT_ERROR_WITHDRAW:
      return {
        ...state,
        paymentError: action.payload,
      };
    default:
      return state;
  }
};

export default paymentReducer;
